import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SvgIcon } from '@mui/material';
import LineChartUp04Icon from 'src/icons/untitled-ui/duocolor/line-chart-up-04';
import { paths } from 'src/paths';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ForumIcon from '@mui/icons-material/Forum';
import GavelIcon from '@mui/icons-material/Gavel';
import { useAuctions } from 'src/store/store';
import HomeSmile from 'src/icons/untitled-ui/duocolor/home-smile';

export interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
}

export interface Section {
  items: Item[];
  subheader?: string;
}

export const useMainSections = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return [
      {
        items: [
          {
            title: 'Dit overblik',
            path: paths.index,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmile />
              </SvgIcon>
            ),
          }
        ],
      },
    ];
  }, []);
};

export const useSections = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return [
      {
        items: [
          {
            title: 'Dine tal',
            path: paths.dashboard.index,
            icon: (
              <SvgIcon fontSize="small">
                <LineChartUp04Icon />
              </SvgIcon>
            ),
          },
          {
            title: 'Henvendelser',
            icon: (
              <SvgIcon fontSize="small">
                <EmojiPeopleIcon />
              </SvgIcon>
            ),
            items: [
              {
                title: 'Samlet',
                path: paths.dashboard.leads.leads,
              },
              {
                title: 'Opkald',
                path: paths.dashboard.leads.calls,
              },
              {
                title: 'E-mail',
                path: paths.dashboard.leads.emails,
              },
            ],
          },
          {
            title: 'Annoncer',
            icon: (
              <SvgIcon fontSize="small">
                <FormatListBulletedIcon />
              </SvgIcon>
            ),
            items: [
              {
                title: 'Aktive',
                path: paths.dashboard.classifieds.active,
              },
              {
                title: 'Solgte',
                path: paths.dashboard.classifieds.sold,
              },
              {
                title: 'Prisændringer',
                path: paths.dashboard.classifieds.priceChange,
              },
              {
                title: 'Medieressourcer',
                path: paths.dashboard.classifieds.asset,
              },
            ],
          },
        ],
      },
    ];
  }, []);
};

export const useAuctionSections = () => {
  const store = useAuctions();
  const activeTitle = `Aktive annoncer ${
    store.activeAuctionCount ? `(${store.activeAuctionCount})` : ''
  }`.trim();

  return [
    {
      items: [
        {
          title: 'Køretøjer til salg',
          icon: (
            <SvgIcon fontSize="small">
              <GavelIcon />
            </SvgIcon>
          ),
          expanded: true,
          path: paths.dashboard.auctions.auctions,
          items: [
            {
              title: activeTitle,
              path: paths.dashboard.auctions.active,
            },
            {
              title: 'Åbne bud',
              path: paths.dashboard.auctions.openbid,
            },
            {
              title: 'Afviste bud',
              path: paths.dashboard.auctions.closedbid,
            },
            {
              title: 'Vundne',
              path: paths.dashboard.auctions.won,
            },
            {
              title: 'Arkiv',
              path: paths.dashboard.auctions.archived,
            },
            {
              title: 'Favoritter',
              path: paths.dashboard.auctions.favorite,
            },
          ],
        },
        {
          title: 'Beskeder',
          icon: (
            <SvgIcon fontSize="small">
              <ForumIcon />
            </SvgIcon>
          ),
          path: paths.dashboard.auctions.messages,
        },
      ],
    },
  ];
};
